import React, { useState } from "react";
import { format } from "date-fns-tz";
import { Box, Text } from "../../_components";
import { TableLoader } from "@samedaycustom/core-ui";
import { ListPage } from "@samedaycustom/core-ui";
import CartStatusTag, { TagType } from "../AbandonedCart/CartStatusTag";
import { textToSentenceCase } from "@samedaycustom/helpers/src";

const head = {
    cells: [
        {
            isSortable: false,
            width: 80,
            key: "Quote ID",
            shouldTruncate: true,
            content: <Text marginLeft="20px">Quote ID</Text>
        },
        {
            isSortable: false,
            width: 150,
            key: "Date",
            content: "Date"
        },
        {
            isSortable: false,
            width: 150,
            key: "Request from",
            content: "Request from"
        },
        {
            isSortable: false,
            width: 150,
            key: "Email address",
            content: "Email address"
        },
        {
            isSortable: false,
            width: 150,
            key: "status",
            content: "Status"
        },
        {
            isSortable: false,
            width: 100,
            key: "Assigned to",
            content: "Assigned To"
        },
        {
            isSortable: false,
            width: 50,
            key: 9,
            content: ""
        }
    ]
};

export default ({
    loading,
    quotes,
    setQuoteId,
}: {
    loading: boolean;
    error: boolean | Error;
    quotes: any;
    setQuoteId: any;
}) => {
    const [page, setPage] = useState<number>(1);

    const row = React.useMemo(
        () =>
            quotes?.map(o => {
                const date = new Date(o?.createdAt || o?.created_at || "");
                const formattedDate = format(date, "MM/dd/yyyy hh:mm a");

                return {
                    cells: [
                        {
                            isSortable: false,
                            width: 80,
                            shouldTruncate: true,
                            content: (<Text marginLeft={"20px"} textTransform="capitalize">{o?.quote_id}</Text>)
                        },
                        {
                            isSortable: false,
                            width: 150,
                            shouldTruncate: true,
                            content: formattedDate
                        },
                        {
                            isSortable: false,
                            width: 150,
                            shouldTruncate: true,
                            content: (
                                <Text textTransform="capitalize">
                                    {`${o?.first_name} ${o?.last_name}`}
                                </Text>
                            )
                        },
                        {
                            isSortable: false,
                            width: 150,
                            shouldTruncate: true,
                            content: o?.email
                        },
                        {
                            isSortable: false,
                            width: 150,
                            shouldTruncate: true,
                            content: (
                                <CartStatusTag type={o?.status ?? "new_request" as TagType} />
                            )
                        },
                        {
                            isSortable: false,
                            width: 100,
                            shouldTruncate: true,
                            content: (
                                <Text>{!o?.assign_to ? 'Unassigned' : textToSentenceCase(o.assign_to)}</Text>
                            )
                        },
                        {
                            isSortable: false,
                            width: 50,
                            shouldTruncate: true,
                            content: (<Box onClick={() => setQuoteId(o?.quote_id)} color={"#007BFF"}>View</Box>)
                        }
                    ]
                };
            }),
        [quotes]
    );

    const onPageChange = ({ options, callback }: any) => {
        if (options.buttonClick === "next") {
            setPage(page + 1);
        } else {
            setPage(page - 1);
        }
        callback();
    };

    if (loading) return <TableLoader />;
    return (
        <Box>
            <ListPage
                totalRowCount={quotes?.length || 0}
                row={row}
                head={head}
                page={page}
                onPageChange={onPageChange}
                rowsPerPage={20}
                EmptyView={"Customers requested quotes will be displayed here"}
            />
        </Box>
    );
};
