import "./style.scss";

import { Box } from "../_components";
import React, { useEffect } from "react";

import { MessageBodyProp } from "./@types";
import { MessageBody } from "./MessageBody";
import { MessageFooter } from "./MessageFooter";
import { MessageInputProp } from "./MessageInput";
import MessageOrderHeader, {
  MessageOrderHeaderProp
} from "./MessageOrderHeader";
import { useDispatch } from "../../store";
import { onAskApproval } from "./context";
import { ISDCMessage } from "@samedaycustom/types/app/Messaging";

/**
 * The goal of this component is so it can be
 * reuseable across multiple platform
 * we current have it static and unstructured on the vendor platform
 * we want to move it here and make it much more reuseable
 *
 * we want to be about to have two ui types
 *
 * type 1: the static page view of the message
 * type 2: the drawer version of the message view
 */

export interface MessageProp
  extends MessageBodyProp,
    MessageInputProp,
    MessageOrderHeaderProp {
  initialInputValue?: ISDCMessage;
  storeAddress?: string;
  reviseText?: (text: string) => any;
}
export const Message: React.FC<MessageProp> = props => {
  const dispatch = useDispatch();
  const previousHasApproval = React.useRef(false);

  useEffect(() => {
    if (props.hasApproval !== previousHasApproval.current) {
      previousHasApproval.current = props.hasApproval;
      dispatch(onAskApproval(props.hasApproval));
    }
  }, [props.hasApproval]);

  return (
    <Box
      d="flex"
      id="message_container"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      className="messageView__ContentContainer"
    >
      {props.showOrderHeader && (
        <MessageOrderHeader
          orderInfo={props.orderInfo}
          onCloseOrderHeader={props.onCloseOrderHeader}
          onViewOrder={props.onViewOrder}
        />
      )}
      <MessageBody
        user={props.user}
        loading={props.loading}
        messages={props.messages}
      />
      <MessageFooter
        onSend={props.onSend}
        isLoading={props.loading}
        isFetchingLineItem={props.isFetchingLineItem}
        lineItems={props.lineItems}
        className={props.inputContainerClassName as string}
        containerStyle={props.inputContainerStyle}
        hideRequireApproval={props.hideRequireApproval}
        onUploadFiles={props.onUploadFiles}
        isAdmin={props.isAdmin}
        storeAddress={props.storeAddress}
        initialInputValue={props.initialInputValue}
        reviseText={props.reviseText}
      />
    </Box>
  );
};
