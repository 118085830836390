import "./style.scss";

import { Box, IconButton, InputGroup, Text, Textarea } from "../_components";
import { ReactComponent as SendSvg } from "@samedaycustom/assets/images/sendsvg.svg";
import {
  AlternativeDropDowns,
  DefaultCheck,
  PrimaryButton,
  SecondaryButton,
  SelectedArtWork
} from "@samedaycustom/core-ui";
import { ISDCMessage } from "@samedaycustom/types/app/Messaging";
import { ISDCLine } from "@samedaycustom/types/order/@types/line";
import React, { useRef } from "react";
import { generate } from "shortid";

import { useDispatch, useSelector } from "../../store";
import {
  messageSelector,
  onAddInputFile,
  onUpdateInputFile,
  onAskApproval,
  onChange,
  onResetInput,
  onLineItemSelected,
  onMessageTypeSelected,
  onRemoveFile
} from "./context";
import { normalizeNewlines } from "@samedaycustom/helpers/src";

// TODO: refactor
/**
 * change setAskPermission to onAskPermission
 * use messageSplice to control the input
 * remove type property we dont need it anymore since we've moved notes from this input
 * change _onSend to onSend
 *
 * move most of the props to messageSplice state
 */

export interface MessageInputProp {
  onSend: (message: ISDCMessage) => void;
  isFetchingLineItem?: boolean;
  lineItems?: ISDCLine[];
  isAdmin?: boolean;
  inputContainerClassName?: string;
  inputContainerStyle?: React.CSSProperties;
  hideRequireApproval?: boolean;
  hasApproval?: boolean;
  onUploadFiles: (files: File[]) => Promise<File[]>;
  isLoading?: boolean;
  storeAddress?: string;
  initialInputValue?: ISDCMessage;
  reviseText?: any;
}
const MessageInput = React.forwardRef(
  (
    {
      onSend,
      isFetchingLineItem,
      lineItems,
      hideRequireApproval,
      onUploadFiles,
      inputContainerClassName,
      inputContainerStyle,
      isAdmin,
      isLoading,
      storeAddress,
      initialInputValue,
      reviseText
    }: MessageInputProp,
    ref: any
  ) => {
    const messageContext = useSelector(messageSelector);
    const dispatch = useDispatch();
    const textAreaRef = useRef<HTMLTextAreaElement>();
    const [loadingRevise, setLoadingRevise] = React.useState(false);
    function autoExpand(field) {
      // Reset field height
      field.style.height = "inherit";

      // Get the computed styles for the element
      var computed = window.getComputedStyle(field);

      // Calculate the height
      var height =
        parseInt(computed.getPropertyValue("border-top-width"), 10) +
        parseInt(computed.getPropertyValue("padding-top"), 10) +
        field.scrollHeight +
        parseInt(computed.getPropertyValue("padding-bottom"), 10) +
        parseInt(computed.getPropertyValue("border-bottom-width"), 10);

      field.style.height = !field.value ? field.style.minHeight : height + "px";
    }

    async function FileGetterWithLoader(event) {
      const files = [];
      const fileList = event.dataTransfer
        ? event.dataTransfer.files
        : event.target.files;

      for (var i = 0; i < fileList.length; i++) {
        const file = fileList.item(i);

        Object.defineProperty(file, "loading", {
          value: true
        });
        Object.defineProperty(file, "id", {
          value: generate()
        });

        files.push(file);
      }

      return files;
    }

    async function _onSend() {
      if (messageContext.input.message.content || messageContext.input.message.files.length) {
        dispatch(onResetInput());
        textAreaRef.current.style.height = textAreaRef.current?.style.minHeight;
        onSend({
          ...messageContext.input.message,
          content: normalizeNewlines(messageContext.input.message.content?.trim() || ""),
        });
      }
    }

    const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      const pastedText = event.clipboardData.getData("text");
      const normalizedText = pastedText.replace(/\r?\n/g, "\n");

      const textarea = event.target as HTMLTextAreaElement;
      const { selectionStart, selectionEnd } = textarea;

      const newValue =
        textarea.value.substring(0, selectionStart) +
        normalizedText +
        textarea.value.substring(selectionEnd);

      dispatch(onChange(newValue || ""));

      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd =
          selectionStart + normalizedText.length;
      }, 0);
    };

    async function handleSendAddress() {
      if (storeAddress && messageContext.input.message) {
        onSend({
          ...messageContext.input.message,
          content: storeAddress,
        });
      }
    }

    async function _onAddFile(e: React.ChangeEventHandler<HTMLInputElement>) {
      const files = await FileGetterWithLoader(e);
      dispatch(onAddInputFile(files));
      const result = await onUploadFiles(files);
      dispatch(onUpdateInputFile(result));
    }

    return (
      <Box
        alignItems="flex-end"
        borderRadius="4px"
        fontSize="0.95rem"
        className={`message__input ${inputContainerClassName ? inputContainerClassName : ""
          }`}
        {...inputContainerStyle}
      >
        {messageContext.input.message?.files?.length > 0 ? (
          <Box className="selectedArtworkGrouping">
            {messageContext.input.message?.files.map((el, i) => (
              <SelectedArtWork
                key={i}
                imageUrl={el?.url}
                removeFunc={() => {
                  dispatch(onRemoveFile(el.id as string));
                }}
                loading={(el as any)?.loading}
                label={el?.name}
              />
            ))}
          </Box>
        ) : null}
        <Box
          background="#fff"
          style={{
            padding: "0 20px"
          }}
          className="TextArea__OuterParent"
        >
          <Box width="100%" d="flex" className="TextArea__InnerParent">
            <Textarea
              ref={textAreaRef}
              className="mainTextArea"
              size="sm"
              minHeight="60px"
              maxHeight="120px"
              transition="all 0.5s ease"
              paddingTop="15px"
              width="100%"
              background="#F0F4F8"
              isDisabled={isLoading}
              border="none"
              multiple
              resize="none"
              placeholder="Write your message here"
              _placeholder={{ color: "#486581" }}
              borderRadius="4px"
              fontSize="1rem"
              onPaste={handlePaste}
              value={messageContext.input.message?.content}
              onChange={(e: any) => {
                const value = e.target?.value;
                dispatch(onChange(value || ""));
                autoExpand(e.target);
              }}
            />
            <IconButton
              aria-label="send button"
              onClick={_onSend}
              className="MobileSendBtn"
            >
              <SendSvg />
            </IconButton>
          </Box>
          <Box
            paddingTop="0.5rem"
            d="flex"
            justifyContent="space-between"
            className="messageOptionsGrp"
          >
            <Box d="flex" justifyContent="space-between" position="relative">
              {!isAdmin && (
                <React.Fragment>
                  <InputGroup marginRight="0.5rem" size="md" width="140px">
                    <AlternativeDropDowns
                      onClick={value => dispatch(onMessageTypeSelected(value))}
                      placeholder="Select Note"
                      value={messageContext.input.message?.type}
                      className="Mobile__inputDropDown"
                      loading={false}
                      position="top center"
                      listItems={[
                        {
                          label: "General Note",
                          value: "GENERAL"
                        },
                        { label: "Artwork", value: "ARTWORK" }
                      ]}
                    />
                  </InputGroup>
                  <InputGroup width="130px" size="md" marginRight="1rem">
                    <AlternativeDropDowns
                      onClick={value => dispatch(onLineItemSelected(value))}
                      placeholder="Select line..."
                      value={messageContext.input.message?.lineID}
                      className="Mobile__inputDropDown"
                      loading={isFetchingLineItem}
                      position="top center"
                      listItems={lineItems.map(el => ({
                        label: el.deco_line_id,
                        value: el.id
                      }))}
                    />
                  </InputGroup>
                </React.Fragment>
              )}
              <label
                htmlFor="attach"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  width: "fit-content"
                }}
              >
                <Box
                  color="#007BFF"
                  d="flex"
                  alignItems="center"
                  background="#E5F1FF"
                  borderRadius="4px"
                  height="36px"
                  padding="0rem 1rem 0 1rem"
                  width="fit-content"
                  className="AttachFileBtn"
                >
                  <input
                    ref={ref}
                    type="file"
                    name=""
                    id="attach"
                    multiple
                    hidden
                    // accept="image/*,.pdf,.cdr,.ai,.psd,.eps"
                    onChange={_onAddFile as any}
                  />
                  <i className="fas fa-paperclip"></i>
                  <Text
                    fontFamily="Inter"
                    fontWeight={500}
                    paddingLeft="0.5rem"
                  >
                    {storeAddress ? "" : "Add"} Files
                  </Text>
                </Box>
              </label>

              {storeAddress ? (<Box
                color="#007BFF"
                d="flex"
                alignItems="center"
                background="#E5F1FF"
                borderRadius="4px"
                height="36px"
                padding="0rem 1rem 0 1rem"
                width="fit-content"
                marginLeft={"10px"}
                marginTop={"2px"}
                onClick={handleSendAddress}
                cursor={"pointer"}
              >
                <Text
                  fontFamily="Inter"
                  fontWeight={500}
                  paddingLeft="0.5rem"
                  whiteSpace={"nowrap"}
                >
                  {(storeAddress && initialInputValue?.origin === 'admin') ? "" : "Send"} Store address
                </Text>
              </Box>) : null}


              {initialInputValue?.origin === 'admin' ? (<SecondaryButton
                className="messageDesktopbtn"
                height="36px"
                onClick={async () => {
                  setLoadingRevise(true);
                  const revisedText = reviseText && await reviseText(messageContext.input.message?.content);
                  dispatch(onChange(revisedText?.data || ""));
                  setLoadingRevise(false);
                }}
                isDisabled={isLoading}
                marginLeft={"10px"}
                marginRight={"10px"}
                mt={"2px"}
                isLoading={loadingRevise}
              >
                Revise {storeAddress ? "" : "text"}
              </SecondaryButton>) : null}
            </Box>
            <PrimaryButton
              className="messageDesktopbtn"
              width="70px"
              height="40px"
              onClick={_onSend}
              isDisabled={isLoading}
            >
              Send
            </PrimaryButton>
          </Box>

          {!hideRequireApproval && (
            <Box className="message__input-checkitem">
              <DefaultCheck
                style={{
                  marginBottom: "0 !important"
                }}
                checked={messageContext.input.message?.options?.available}
                label=""
                height={16}
                width={16}
                setChecked={value => {
                  dispatch(onAskApproval(value));
                }}
              />
              <span> Require approval from customer </span>
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);

export default React.memo(MessageInput);
